import React, { useState } from "react";

// reactstrap components
import {
  UncontrolledCollapse,
  NavItem,
  NavLink,
  Nav,
  Container,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";

import{
  Card,
  CardBody
} from "reactstrap";

// core components
import Menu from "./MenuHeaderComponents/ByModel/Menu";
import MenuByApplication from "./MenuHeaderComponents/ByApplication/Menu";
// import MenuHeaderFooter from "components/Footers/MenuHeaderFooter";
import CustomOrder from "./MenuHeaderComponents/CustomOrder/CustomOrder";
import HelpMeChoose from "./MenuHeaderComponents/HelpMeChoose/HelpMeChoose";

function MenuHeader({showTopSlider}) {
  const [pills, setPills] = useState("1");
  return (
    <>
      <div>
          <Card>
            <Container>
              <CardBody>
                <Row>
                  <Col md="2" style={{ 'margin-top': '40px', 'border-right': '1px solid #ebf2ff', 'margin-bottom': '0px'}}>
                    <Nav className="flex-column">
                      <NavItem className={pills === "1" ? "active" : ""}>
                        <NavLink
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("1");
                          }}
                          style={pills === "1"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                        >
                          <p className="nav-product-menu-outside">BY MODEL</p>
                        </NavLink>
                      </NavItem>

                      <NavItem className={pills === "2" ? "active" : ""}>
                        <NavLink
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("2");
                          }}
                          style={pills === "2"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                        >
                          <p className="nav-product-menu-outside">By APPLICATION</p>
                        </NavLink>
                      </NavItem>

                      {/* <NavItem className={pills === "3" ? "active" : ""}>
                        <NavLink
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("3");
                          }}
                          style={pills === "3"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                        >
                          <p className="nav-product-menu-outside">HELP ME CHOOSE</p>
                        </NavLink>
                      </NavItem>

                      <NavItem className={pills === "4" ? "active" : ""}>
                        <NavLink
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setPills("4");
                          }}
                          style={pills === "4"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                        >
                          <p className="nav-product-menu-outside">CUSTOM ORDER</p>
                        </NavLink>
                      </NavItem> */}
                    </Nav>
                  </Col>

                  <Col md="10" style={{ 'margin-top': '40px' }}>
                    <TabContent className="gallery" activeTab={"pills" + pills}>
                      <TabPane tabId="pills1">
                        <Menu showTopSlider={showTopSlider}/>
                      </TabPane>
                      <TabPane tabId="pills2">
                        <MenuByApplication />
                      </TabPane>
                      <TabPane tabId="pills3" style={{ 'min-height': '598px' }}>
                        <HelpMeChoose />
                      </TabPane>
                      <TabPane tabId="pills4" style={{ 'min-height': '598px' }}>
                        <CustomOrder />
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </CardBody>
            </Container>
            <NavLink className="allProductsLink" href="/allproducts">
              View All Products
            </NavLink>
            {/* <MenuHeaderFooter /> */}
          </Card>
      </div>
    </>
  );
}

export default MenuHeader;

import React, { useState } from "react";
import { Link } from "react-router-dom";

import{
  Card,
  CardImg,
  CardBody,
  CardText,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import HPXInlineImg from 'assets/img/navbar/PURE/Inline/PURE-LM-Series-800-HPX/Series-800-HPX-Inline.png';
import HPNInlineImg from 'assets/img/navbar/PURE/Inline/PURE-RB-Series-800-HPN/Series-800-HPN-Inline.png';

import InlineIcon from  'assets/img/inline_primary.svg';
import InsertionIcon from  'assets/img/insertion_primary.svg';

function Pure({data, showTopSlider}) {
  const product_data = [
    {
      title: 'PUREGEN-LM',
      link: 'pure-lm',
      description: 'EPI’s High Purity Flow Meter with an Explosion-Proof Rated Enclosure',
      type: 1,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      left_icon: InlineIcon,
      image: HPXInlineImg,
      default_image: HPXInlineImg,
      left_image: HPXInlineImg,
      right_image: HPXInlineImg
    },
    {
      title: 'PUREGEN-RB',
      link: 'pure-rb',
      description: 'EPI’s High Purity Flow Meter with a NEMA 4X Rated (IP66) Enclosure',
      type: 1,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      left_icon: InlineIcon,
      image: HPNInlineImg,
      default_image: HPNInlineImg,
      left_image: HPNInlineImg,
      right_image: HPNInlineImg
    }
  ]

  const link_dir_inline = '/products/pure/inline/';
  const link_dir_insertion = '/products/pure/insertion/';
  const [productData, setProductData] = useState(product_data)
  const [viewType, setViewType] = useState('')

  const updateProductImage = (data, index, side) => {
    if(side == 'left'){
      data.image = data.left_image
      product_data[index] = data;
      setProductData(product_data);
    }

    if(side == 'right'){
      data.image = data.right_image
      product_data[index] = data;
      setProductData(product_data);
    }
  }

  const updateProductDefaultImage = (data, index) => {
    data.image = data.default_image
    product_data[index] = data;
    setProductData(product_data);
  }

  return (
    <>
      <p className="nav-product-main-title">{ data.page_title }</p>
      <p className="nav-product-main-description">{ data.page_description }</p>

      <div className="text-center mainNav">
        {
          productData.map((value, index) => {
            if(value.type == 2){
              return(
                <Card className="mainNavCards">
                  <CardImg
                    alt="..."
                    src={ value.image }
                    top
                  ></CardImg>
                  <CardBody className="text-center">
                    <CardText className="flexible">
                      <p className="nav-product-title text-center">{ value.title }</p>
                      <p className="nav-product-description text-center">{ value.description }</p>
                    </CardText>
                    <Row className="noPaddingRow">
                      <Col>
                        <Nav
                          className="nav-pills-info nav-pills-just-icons noPaddingLink"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className="nav-product-hover-button betterLink"
                              tag={Link}
                              from="*"
                              to={link_dir_inline + value.link}
                              onMouseEnter={() => updateProductImage(value, index, 'left')}
                              onMouseLeave={() => updateProductDefaultImage(value, index)}
                              onClick={(e) => showTopSlider()}
                            >
                              <i className="animated" style={{ 'line-height': '55px'}}> <img src={ value.right_icon } style={{ 'width': '40px'}} /></i>
                              <p className="nav-product-hover-button-title">{ value.left_small_title }</p>
                              <p className="nav-product-hover-button-size">{ value.left_size_info }</p>
                              <p className="nav-product-hover-button-info">{ value.left_small_info }</p>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>

                      <Col>
                        <Nav
                          className="nav-pills-info nav-pills-just-icons noPaddingLink"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className="nav-product-hover-button betterLink"
                              tag={Link}
                              from="*"
                              to={link_dir_insertion + value.link}
                              onMouseEnter={() => updateProductImage(value, index, 'right')}
                              onMouseLeave={() => updateProductDefaultImage(value, index)}
                              onClick={(e) => showTopSlider()}
                            >
                              <i className="animated" style={{ 'line-height': '55px'}}> <img src={ value.left_icon } style={{ 'width': '40px'}} /></i>
                              <p className="nav-product-hover-button-title">{ value.right_small_title }</p>
                              <p className="nav-product-hover-button-size">{ value.right_size_info }</p>
                              <p className="nav-product-hover-button-info">{ value.right_small_info }</p>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
            } else {
              return(
                <Card className="mainNavCards">
                  <CardImg
                    alt="..."
                    src={ value.image }
                    top
                  ></CardImg>
                  <CardBody>
                    <CardText className="flexible">
                      <p className="nav-product-title">{ value.title }</p>
                      <p className="nav-product-description">{ value.description }</p>
                    </CardText>
                    <Row className="noPaddingRow">
                      <Col>
                        <Nav
                          className="nav-pills-info nav-pills-just-icons noPaddingLink"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className="nav-product-hover-button betterLink"
                              tag={Link}
                              from="*"
                              to={link_dir_inline + value.link}
                              onMouseEnter={() => updateProductImage(value, index, 'left')}
                              onMouseLeave={() => updateProductDefaultImage(value, index)}
                              onClick={(e) => showTopSlider()}
                            >
                              <i className="animated" style={{ 'line-height': '55px'}}> <img src={ value.left_icon } style={{ 'width': '40px'}} /></i>
                              <p className="nav-product-hover-button-title">{ value.left_small_title }</p>
                              <p className="nav-product-hover-button-size">{ value.left_size_info }</p>
                              <p className="nav-product-hover-button-info">{ value.left_small_info }</p>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
            }
          })
        }
      </div>
    </>
  )
}

export default Pure;

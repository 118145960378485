function ShoppingCart(props) {

  if (!ReadCart()) {
    CreateCart();
  };

  switch(props['task']) {
    case 'read':
      return ReadCart();
      break;
    case 'read_total':
      return ReadTotal();
      break;
    case 'update':
      UpdateCart(props['data'])
      break;
    case 'update_total':
      UpdateTotalPrice(props['data'])

      if (props['order_data']) {
        UpdateOrderType(props['order_data'])
        // console.log('This is order type data =>', props['order_data'])
      };
      break;
    case 'remove':
      RemoveSingleProduct(props['data'])
      break;
    case 'delete':
      DeleteCart()
      break;
    case 'updateSingle':
      updateSingleProduct(props['data'])
      break;
    case 'updateSingleState':
      updateSingleProductState(props['data'])
      break;
    case 'updateSingleConfig':
      updateSingleConfig(props['data'])
      break;
    default:
      //
  }
}

export function CreateCart() {
  const cartItemsJson = { "cart_items":[], "total_price":[], "ci":[] };
  localStorage.setItem('shopping_cart_session', JSON.stringify(cartItemsJson));
}

export function ReadCart() {
  return JSON.parse(localStorage.getItem('shopping_cart_session'))
}

export function ReadTotal() {
  var cart = JSON.parse(localStorage.getItem('shopping_cart_session'))
  return cart['total_price'];
}

export function UpdateCart(cartInfo) {
  var user_session = JSON.parse(localStorage.getItem('shopping_cart_session'))
  user_session['cart_items'].push(cartInfo);
  user_session['ci'].push(cartInfo._id);

  var jsonStr = JSON.stringify(user_session);
  localStorage.setItem('shopping_cart_session', jsonStr);
}

export function UpdateTotalPrice(newTotal) {
  var user_session = JSON.parse(localStorage.getItem('shopping_cart_session'))
  user_session['total_price'] = [];
  user_session['total_price'].push(newTotal);

  var jsonStr = JSON.stringify(user_session);
  localStorage.setItem('shopping_cart_session', jsonStr);
}

function UpdateOrderType(orderType) {
  var user_session = JSON.parse(localStorage.getItem('shopping_cart_session'))
  user_session['ot'] = [];
  user_session['ot'].push(orderType);

  var jsonStr = JSON.stringify(user_session);
  localStorage.setItem('shopping_cart_session', jsonStr);
}

function updateSingleProduct(singleProduct) {
  var user_session = JSON.parse(localStorage.getItem('shopping_cart_session'))
  user_session['cart_items'][singleProduct['cart_location']].quantity = singleProduct['quantity']
  // console.log(user_session['cart_items'][singleProduct['cart_location']])

  var jsonStr = JSON.stringify(user_session);
  localStorage.setItem('shopping_cart_session', jsonStr);
}

function updateSingleProductState(singleProduct) {
  var user_session = JSON.parse(localStorage.getItem('shopping_cart_session'))
  user_session['cart_items'][singleProduct['cart_location']].state = singleProduct['state'];
  user_session['cart_items'][singleProduct['cart_location']].total = singleProduct['total'];
  // console.log(user_session['cart_items'][singleProduct['cart_location']])

  var jsonStr = JSON.stringify(user_session);
  localStorage.setItem('shopping_cart_session', jsonStr);
}

function updateSingleConfig(singleProduct) {
  var user_session = JSON.parse(localStorage.getItem('shopping_cart_session'))
  user_session['cart_items'][singleProduct['cart_location']] = singleProduct['meter_config'];
  user_session['ci'][singleProduct['cart_location']] = singleProduct['_id'];

  var jsonStr = JSON.stringify(user_session);
  localStorage.setItem('shopping_cart_session', jsonStr);
}

function RemoveSingleProduct(singleProduct) {
  var user_session = JSON.parse(localStorage.getItem('shopping_cart_session'))
  user_session['cart_items'].splice(singleProduct['cart_location'], 1);
  user_session['ci'].splice(singleProduct['cart_location'], 1);

  var jsonStr = JSON.stringify(user_session);
  localStorage.setItem('shopping_cart_session', jsonStr);
}

function DeleteCart() {
  localStorage.removeItem('shopping_cart_session');
}

export default ShoppingCart;
import React, { useState } from "react";
import { Link } from "react-router-dom";

import{
  Card,
  CardImg,
  CardBody,
  CardText,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import hazInlineImg from 'assets/img/navbar/Haz/Series-8600-8700-MP-Inline.png';
import hazInsertionImg from 'assets/img/navbar/Haz/Series-8800-MP-Insertion.png';

import hazRInlineImg from 'assets/img/navbar/Haz/Series-8000-8100-MP-Inline-Remote.png';
import hazRInsertionImg from 'assets/img/navbar/Haz/Series-8200-MP-Insertion-Remote.png';

import hazFatInlineImg from 'assets/img/navbar/Haz/Series-9700-MP-Inline-FAT.png';
import hazFatInsertionImg from 'assets/img/navbar/Haz/Series-9800-MP-Insertion-FAT.png';

import hazFatRInlineImg from 'assets/img/navbar/Haz/Series-9100-MP-Inline-Remote-FAT.png';
import hazFatRInsertionImg from 'assets/img/navbar/Haz/Series-9200-MP-Insertion-Remote-FAT.png';

import InlineIcon from  'assets/img/inline_primary.svg';
import InsertionIcon from  'assets/img/insertion_primary.svg';

function Haz({data, showTopSlider}) {
  const product_data = [
    {
      title: 'HAZ',
      link: 'haz',
      description: 'Certified for Hazardous Locations',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: hazInlineImg,
      default_image: hazInlineImg,
      left_image: hazInlineImg,
      right_image: hazInsertionImg
    },
    {
      title: 'HAZ R',
      link: 'haz-r',
      description: 'Transmitter Certified for Hazardous Locations with Remote Electronics',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: hazRInlineImg,
      default_image: hazRInlineImg,
      left_image: hazRInlineImg,
      right_image: hazRInsertionImg
    },
    {
      title: 'HAZ FAT',
      link: 'haz-fat',
      description: 'EPI’s Patented Flow Averaging Tube Technology with a Certified for Hazardous Locations',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: hazFatInlineImg,
      default_image: hazFatInlineImg,
      left_image: hazFatInlineImg,
      right_image: hazFatInsertionImg
    },
    {
      title: 'HAZ FAT R',
      link: 'haz-fat-r',
      description: 'EPI’s Patented Flow Averaging Tube Technology with a Transmitter Certified for Hazardous Locations and Remote Electronics',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: hazFatRInlineImg,
      default_image: hazFatRInlineImg,
      left_image: hazFatRInlineImg,
      right_image: hazFatRInsertionImg
    }
  ]

  const link_dir_inline = '/products/haz/inline/';
  const link_dir_insertion = '/products/haz/insertion/';
  const [productData, setProductData] = useState(product_data)

  const updateProductImage = (data, index, side) => {
    if(side == 'left'){
      data.image = data.left_image
      product_data[index] = data;
      setProductData(product_data);
    }

    if(side == 'right'){
      data.image = data.right_image
      product_data[index] = data;
      setProductData(product_data);
    }
  }

  const updateProductDefaultImage = (data, index) => {
    data.image = data.default_image
    product_data[index] = data;
    setProductData(product_data);
  }

  return (
    <>
      <p className="nav-product-main-title">{ data.page_title }</p>
      <p className="nav-product-main-description">{ data.page_description }</p>


      <div className="text-center mainNav">
        {
          productData.map((value, index) => {
            if(value.type == 2){
              return(
                <Card className="mainNavCards">
                  <CardImg
                    alt="..."
                    src={ value.image }
                    top
                  ></CardImg>
                  <CardBody>
                    <CardText className="flexible">
                      <p className="nav-product-title">{ value.title }</p>
                      <p className="nav-product-description">{ value.description }</p>
                    </CardText>
                    <Row className="noPaddingRow">
                      <Col>
                        <Nav
                          className="nav-pills-info nav-pills-just-icons noPaddingLink"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className="nav-product-hover-button betterLink"
                              tag={Link}
                              from="*"
                              to={link_dir_inline + value.link}
                              onMouseEnter={() => updateProductImage(value, index, 'left')}
                              onMouseLeave={() => updateProductDefaultImage(value, index)}
                              onClick={(e) => showTopSlider()}
                            >
                              <i className="animated" style={{ 'line-height': '55px'}}> <img src={ value.right_icon } style={{ 'width': '40px'}} /></i>
                              <p className="nav-product-hover-button-title">{ value.left_small_title }</p>
                              <p className="nav-product-hover-button-size">{ value.left_size_info }</p>
                              <p className="nav-product-hover-button-info">{ value.left_small_info }</p>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>

                      <Col>
                        <Nav
                          className="nav-pills-info nav-pills-just-icons noPaddingLink"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className="nav-product-hover-button betterLink"
                              tag={Link}
                              from="*"
                              to={link_dir_insertion + value.link}
                              onMouseEnter={() => updateProductImage(value, index, 'right')}
                              onMouseLeave={() => updateProductDefaultImage(value, index)}
                              onClick={(e) => showTopSlider()}
                            >
                              <i className="animated" style={{ 'line-height': '55px'}}> <img src={ value.left_icon } style={{ 'width': '40px'}} /></i>
                              <p className="nav-product-hover-button-title">{ value.right_small_title }</p>
                              <p className="nav-product-hover-button-size">{ value.right_size_info }</p>
                              <p className="nav-product-hover-button-info">{ value.right_small_info }</p>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
            } else {
              return(
                <Card className="mainNavCards">
                  <CardImg
                    alt="..."
                    src={ value.image }
                    top
                  ></CardImg>
                  <CardBody>
                    <CardText className="flexible">
                      <p className="nav-product-title">{ value.title }</p>
                      <p className="nav-product-description">{ value.description }</p>
                    </CardText>
                    <Row className="noPaddingRow">
                      <Col>
                        <Nav
                          className="nav-pills-info nav-pills-just-icons noPaddingLink"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className="nav-product-hover-button betterLink"
                              tag={Link}
                              from="*"
                              to={link_dir_inline + value.link}
                              onMouseEnter={() => updateProductImage(value, index, 'left')}
                              onMouseLeave={() => updateProductDefaultImage(value, index)}
                              onClick={(e) => showTopSlider()}
                            >
                              <i className="animated" style={{ 'line-height': '55px'}}> <img src={ value.right_icon } style={{ 'width': '40px'}} /></i>
                              <p className="nav-product-hover-button-title">{ value.left_small_title }</p>
                              <p className="nav-product-hover-button-size">{ value.left_size_info }</p>
                              <p className="nav-product-hover-button-info">{ value.left_small_info }</p>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
            }
          })
        }
      </div>
    </>
  )
}

export default Haz;

import React, { useState } from "react";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane
} from "reactstrap";

// core components
import Haz from "./Haz";
import Gen from "./Gen";
import Val from "./Val";
import Pure from "./Pure";
import Xt from "./Xt";
import Multigen from "./Multigen";
import Switch from "./Switch";
import Bigen from "./Bigen";
import Axgen from "./Axgen";
import Ragen from "./Ragen";

function Menu({showTopSlider}){
  const [plainTabs, setPlainTabs] = useState("1");

  return (
    <>
      <Card className="card-nav-tabs card-plain">
        <CardHeader className="card-header-danger">
          <div>
            <div>
              <Nav>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("1");
                    }}
                    style={plainTabs === "1"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-HAZ</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("2");
                    }}
                    style={plainTabs === "2"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-GEN</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "3" ? "active" : ""}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("3");
                    }}
                    style={plainTabs === "3"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-VAL</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "4" ? "active" : ""}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("4");
                    }}
                    style={plainTabs === "4"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-PURE</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "5" ? "active" : ""}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("5");
                    }}
                    style={plainTabs === "5"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-XT</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "6" ? "active" : ""}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("6");
                    }}
                    style={plainTabs === "6"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-MULTIGEN</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "7" ? "active" : ""}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("7");
                    }}
                    style={plainTabs === "7"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-SWITCH</p>
                  </NavLink>
                </NavItem>

                {/* <NavItem>
                  <NavLink
                    className={plainTabs === "8" ? "active" : ""}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("8");
                    }}
                    style={plainTabs === "8"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-BIGEN</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "9" ? "active" : ""}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("9");
                    }}
                    style={plainTabs === "9"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-AXGEN</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "10" ? "active" : ""}
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("10");
                    }}
                    style={plainTabs === "10"? ({ 'padding': '0.4rem', 'border-bottom': '2px solid' }) : ({ 'padding': '0.4rem'})}
                  >
                    <p className="nav-product-menu-inside">EPI-RAGEN</p>
                  </NavLink>
                </NavItem> */}
              </Nav>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <TabContent
            activeTab={"plainTabs" + plainTabs}
          >
            <TabPane tabId="plainTabs1">
              <Haz
                data={
                  {
                    page_title: 'EPI-HAZ',
                    page_description: 'HAZ flow meters are certified for Hazardous Locations with an explosion-proof enclosure.'
                  }
                }
                showTopSlider={showTopSlider}
              />
            </TabPane>
            <TabPane tabId="plainTabs2">
              <Gen
                data={
                  {
                    page_title: 'EPI-GEN',
                    page_description: 'GEN flow meters are certified for Ordinary/General Locations with an corrosion resistant, watertight enclosure.'
                  }
                }
                showTopSlider={showTopSlider}
              />
            </TabPane>
            <TabPane tabId="plainTabs3">
              <Val
                data={
                  {
                    page_title: 'EPI-VAL',
                    page_description: 'VAL flow meters use the same accurate sensor technology, but offer a lower-cost alternative for those who do not need advanced features.'
                  }
                }
                showTopSlider={showTopSlider}
              />
            </TabPane>
            <TabPane tabId="plainTabs4">
              <Pure
                data={
                  {
                    page_title: 'EPI-PURE',
                    page_description: 'PURE thermal mass flow meters are specifically engineered for ultra high-purity applications.'
                  }
                }
                showTopSlider={showTopSlider}
              />
            </TabPane>
            <TabPane tabId="plainTabs5">
              <Xt
                data={
                  {
                    page_title: 'EPI-XT',
                    page_description: 'EPI XT mass flow meters are designed to measure gas flow in extreme temperature environments.'
                  }
                }
                showTopSlider={showTopSlider}
              />
            </TabPane>
            <TabPane tabId="plainTabs6">
              <Multigen
                data={
                  {
                    page_title: 'EPI-MULTIGEN',
                    page_description: 'The EPI MULTI series flow meters are multi-point, which use multiple connected sensor assemblies connected to a remote control panel for metering large pipes/ducts.'
                  }
                }
                showTopSlider={showTopSlider}
              />
            </TabPane>
            <TabPane tabId="plainTabs7">
              <Switch
                data={
                  {
                    page_title: 'EPI-SWITCH',
                    page_description: 'SWITCH flow switches are built with an explosion-proof rated enclosure and can be used to monitor gas flow accurately.'
                  }
                }
                showTopSlider={showTopSlider}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
}

export default Menu;

import React, { useState } from "react";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane
} from "reactstrap";

// core components
import Haz from "../ByModel/Haz";
import Gen from "../ByModel/Gen";
import Val from "../ByModel/Val";
import Pure from "../ByModel/Pure";
import Xt from "../ByModel/Xt";
import Multigen from "../ByModel/Multigen";
import Switch from "../ByModel/Switch";
import Bigen from "../ByModel/Bigen";
import Axgen from "../ByModel/Axgen";
import Ragen from "../ByModel/Ragen";

function Menu(){
  const [plainTabs, setPlainTabs] = useState("1");

  return (
    <>
      <Card className="card-nav-tabs card-plain">
        <CardHeader className="card-header-danger">
          <div>
            <div>
              <Nav>
                <NavItem>
                  <NavLink
                    className={plainTabs === "1" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("1");
                    }}
                    style={{ 'padding': '0.4rem' }}
                  >
                    <p className="nav-product-menu-inside">Hazardous Locations</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "2" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("2");
                    }}
                    style={{ 'padding': '0.4rem' }}
                  >
                    <p className="nav-product-menu-inside">General Locations</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "3" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("3");
                    }}
                    style={{ 'padding': '0.4rem' }}
                  >
                    <p className="nav-product-menu-inside">High Value</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "4" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("4");
                    }}
                    style={{ 'padding': '0.4rem' }}
                  >
                    <p className="nav-product-menu-inside">High Purity</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "5" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("5");
                    }}
                    style={{ 'padding': '0.4rem' }}
                  >
                    <p className="nav-product-menu-inside">High Temperature</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "6" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("6");
                    }}
                    style={{ 'padding': '0.4rem' }}
                  >
                    <p className="nav-product-menu-inside">Multi-Sensor</p>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={plainTabs === "7" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setPlainTabs("7");
                    }}
                    style={{ 'padding': '0.4rem' }}
                  >
                    <p className="nav-product-menu-inside">Flow Switch</p>
                  </NavLink>
                </NavItem>

              </Nav>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <TabContent
            activeTab={"plainTabs" + plainTabs}
          >
            <TabPane tabId="plainTabs1">
              <Haz data={
                {
                  page_title: 'Hazardous Locations Flow Meters',
                  page_description: 'HAZ flow meters are certified for Hazardous Locations with an explosion-proof enclosure.'
                }
              } />
            </TabPane>
            <TabPane tabId="plainTabs2">
              <Gen data={
                {
                  page_title: 'General Locations Flow Meters',
                  page_description: 'GEN flow meters are certified for Ordinary/General Locations with an corrosion resistant, watertight enclosure.'
                }
              } />
            </TabPane>
            <TabPane tabId="plainTabs3">
              <Val data={
                {
                  page_title: 'High Value Low Cost Flow Meters',
                  page_description: 'VAL flow meters use the same accurate sensor technology, but offer a lower-cost alternative for those who do not need advanced features.'
                }
              } />
            </TabPane>
            <TabPane tabId="plainTabs4">
              <Pure data={
                {
                  page_title: 'High Purity Flow Meters',
                  page_description: 'PURE thermal mass flow meters are specifically engineered for ultra high-purity applications.'
                }
              } />
            </TabPane>
            <TabPane tabId="plainTabs5">
              <Xt data={
                {
                  page_title: 'High Temperature Flow Meters',
                  page_description: 'EPI XT mass flow meters are designed to measure gas flow in extreme temperature environments.'
                }
              } />
            </TabPane>
            <TabPane tabId="plainTabs6">
              <Multigen data={
                {
                  page_title: 'Multiple Sensor Flow Meters',
                  page_description: 'The EPI MULTI series flow meters are multi-point, which use multiple connected sensor assemblies connected to a remote control panel for metering large pipes/ducts.'
                }
              } />
            </TabPane>
            <TabPane tabId="plainTabs7">
              <Switch data={
                {
                  page_title: 'Flow Switches',
                  page_description: 'SWITCH flow switches are built with an explosion-proof rated enclosure and can be used to monitor gas flow accurately.'
                }
              } />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
}

export default Menu;

import React, { useState } from "react";
import { Link } from "react-router-dom";

import{
  Card,
  CardImg,
  CardBody,
  CardText,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import GENInlineImg from 'assets/img/navbar/GEN/Inline/Series-8600-8700-MPNH-Inline.png';
import GENInsertionImg from 'assets/img/navbar/GEN/Insertion/Series-8800-MPNH-Insertion.png';

import GENRInlineImg from 'assets/img/navbar/GEN/Inline/Series-8000-8100-MPNH-Inline-Remote.png';
import GENRInsertionImg from 'assets/img/navbar/GEN/Insertion/Series-8200-MPNH-Insertion-Remote.png';

import GENFatInlineImg from 'assets/img/navbar/GEN/Inline/Series-9700-MPNH-Inline-FAT.png';
import GENFatInsertionImg from 'assets/img/navbar/GEN/Insertion/Series-9800-MPNH-Insertion-FAT.png';

import GENFatRInlineImg from 'assets/img/navbar/GEN/Inline/Series-9100-MPNH-Inline-Remote-FAT.png';
import GENFatRInsertionImg from 'assets/img/navbar/GEN/Insertion/Series-9200-MPNH-Insertion-Remote-FAT.png';

import InlineIcon from  'assets/img/inline_primary.svg';
import InsertionIcon from  'assets/img/insertion_primary.svg';

function Gen({data, showTopSlider}) {
  const product_data = [
    {
      title: 'GEN',
      link: 'gen',
      description: 'Certified for Ordinary Locations',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: GENInlineImg,
      default_image: GENInlineImg,
      left_image: GENInlineImg,
      right_image: GENInsertionImg
    },
    {
      title: 'GEN R',
      link: 'gen-r',
      description: 'Transmitter Certified for Ordinary Locations with Remote Electronics',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '1/4-4"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 1 1/2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: GENRInlineImg,
      default_image: GENRInlineImg,
      left_image: GENRInlineImg,
      right_image: GENRInsertionImg
    },
    {
      title: 'GEN FAT',
      link: 'gen-fat',
      description: 'EPI’s Patented Flow Averaging Tube Technology with a Transmitter Certified for Ordinary Locations',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '2-6"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: GENFatInlineImg,
      default_image: GENFatInlineImg,
      left_image: GENFatInlineImg,
      right_image: GENFatInsertionImg
    },
    {
      title: 'GEN FAT R',
      link: 'gen-fat-r',
      description: 'EPI’s Patented Flow Averaging Tube Technology with a Transmitter Certified for Ordinary Locations and Remote Electronics',
      type: 2,
      left_small_title: 'INLINE',
      left_size_info: '2-6"',
      left_small_info: 'Line Size',
      right_small_title: 'INSERTION',
      right_size_info: 'MIN 2"',
      right_small_info: 'Line Size',
      right_icon: InlineIcon,
      left_icon: InsertionIcon,
      image: GENFatRInlineImg,
      default_image: GENFatRInlineImg,
      left_image: GENFatRInlineImg,
      right_image: GENFatRInsertionImg
    }
  ]

  const link_dir_inline = '/products/gen/inline/';
  const link_dir_insertion = '/products/gen/insertion/';
  const [productData, setProductData] = useState(product_data)

  const updateProductImage = (data, index, side) => {
    if(side == 'left'){
      data.image = data.left_image
      product_data[index] = data;
      setProductData(product_data);
    }

    if(side == 'right'){
      data.image = data.right_image
      product_data[index] = data;
      setProductData(product_data);
    }
  }

  const updateProductDefaultImage = (data, index) => {
    data.image = data.default_image
    product_data[index] = data;
    setProductData(product_data);
  }

  return (
    <>
      <p className="nav-product-main-title">{ data.page_title }</p>
      <p className="nav-product-main-description">{ data.page_description }</p>

      <div className="text-center mainNav">
        {
          productData.map((value, index) => {
            if(value.type == 2){
              return(
                <Card className="mainNavCards">
                  <CardImg
                    alt="..."
                    src={ value.image }
                    top
                  ></CardImg>
                  <CardBody>
                    <CardText className="flexible">
                      <p className="nav-product-title">{ value.title }</p>
                      <p className="nav-product-description">{ value.description }</p>
                    </CardText>
                    <Row className="noPaddingRow">
                      <Col>
                        <Nav
                          className="nav-pills-info nav-pills-just-icons noPaddingLink"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className="nav-product-hover-button betterLink"
                              tag={Link}
                              from="*"
                              to={link_dir_inline + value.link}
                              onMouseEnter={() => updateProductImage(value, index, 'left')}
                              onMouseLeave={() => updateProductDefaultImage(value, index)}
                              onClick={(e) => showTopSlider()}
                            >
                              <i className="animated" style={{ 'line-height': '55px'}}> <img src={ value.right_icon } style={{ 'width': '40px'}} /></i>
                              <p className="nav-product-hover-button-title">{ value.left_small_title }</p>
                              <p className="nav-product-hover-button-size">{ value.left_size_info }</p>
                              <p className="nav-product-hover-button-info">{ value.left_small_info }</p>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>

                      <Col>
                        <Nav
                          className="nav-pills-info nav-pills-just-icons noPaddingLink"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                             <NavLink
                              className="nav-product-hover-button betterLink"
                              tag={Link}
                              from="*"
                              to={link_dir_insertion + value.link}
                              onMouseEnter={() => updateProductImage(value, index, 'right')}
                              onMouseLeave={() => updateProductDefaultImage(value, index)}
                              onClick={(e) => showTopSlider()}
                            >
                              <i className="animated" style={{ 'line-height': '55px'}}> <img src={ value.left_icon } style={{ 'width': '40px'}} /></i>
                              <p className="nav-product-hover-button-title">{ value.right_small_title }</p>
                              <p className="nav-product-hover-button-size">{ value.right_size_info }</p>
                              <p className="nav-product-hover-button-info">{ value.right_small_info }</p>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
            } else {
              return(
                <Card style={{ width: "13rem", 'margin-right': '11px' }}>
                  <CardImg
                    alt="..."
                    src={ value.image }
                    top
                  ></CardImg>
                  <CardBody>
                    <CardText>
                      <p className="nav-product-title">{ value.title }</p>
                      <p className="nav-product-description">{ value.description }</p>
                    </CardText>
                    <Row className="noPaddingRow">
                      <Col>
                        <Nav
                          className="nav-pills-info nav-pills-just-icons noPaddingLink"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              className="nav-product-hover-button betterLink"
                              href="#pablo"
                              onMouseEnter={() => updateProductImage(value, index, 'left')}
                              onMouseLeave={() => updateProductDefaultImage(value, index)}
                            >
                              <i className="animated" style={{ 'line-height': '55px'}}> <img src={ value.right_icon } style={{ 'width': '40px'}} /></i>
                              <p className="nav-product-hover-button-title">{ value.left_small_title }</p>
                              <p className="nav-product-hover-button-size">{ value.left_size_info }</p>
                              <p className="nav-product-hover-button-info">{ value.left_small_info }</p>
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )
            }
          })
        }
      </div>
    </>
  )
}

export default Gen;
